import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import faviconSvg from "../assets/ico/favicon.svg";
import faviconIco from "../assets/ico/favicon.ico";

type SEOProps = {
  description?: string;
  title?: string;
  image?: string;
};

export const SEO: React.FC<SEOProps> = ({
  children,
  description,
  title,
  image,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitter
          keywords
          url
          facebook
        }
      }
    }
  `);
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      {/* Fav Icons */}
      <link rel="icon" type="image/svg+xml" href={faviconSvg} />
      <link rel="alternate icon" href={faviconIco} />
      {/* Canonical Tags */}
      <link rel="canonical" href="https://acssuplb.org/" />
      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      <meta
        property="fb:page_id"
        content={site.siteMetadata.facebook}
        key="ogdesc"
      />
      <meta
        property="fb:url"
        content={site.siteMetadata.facebook}
        key="ogdesc"
      />
      <meta
        property="twitter:card"
        content="summary_large_image"
        key="ogdesc"
      />
      <meta
        property="twitter:site"
        content={site.siteMetadata.twitter}
        key="ogdesc"
      />
      <meta
        property="twitter:url"
        content={`twitter.com/${site.siteMetadata.twitter.slice(1)}`}
        key="ogdesc"
      />
      {/* Open Graph */}
      {/* {site.siteMetadata.location && (
        <meta property="og:url" content={site.siteMetadata.location} />
      )} */}
      <meta property="og:local" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image || "/logo.svg"} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta property="og:description" content={description} key="ogdesc" />
      <meta property="og:url" content="https://acssuplb.org" />
      <meta
        property="og:site_name"
        content="Alliance of Computer Science Students UPLB"
      />

      {children}
    </Helmet>
  );
};
